// Sidebar light

.app-sidebar {
  &--light {
    .app-sidebar--userbox {
      color: $black;
      // background: rgba($primary, .025);

      .text-white-50 {
        color: rgba($black, 0.5) !important;
      }

      .btn-userbox {
        background: rgba($primary, 0.1);
        color: rgba($primary, 0.8);

        &:hover {
          background: rgba($primary, 0.2);
          color: rgba($primary, 1);
        }
      }

      .avatar-icon {
        box-shadow: 0 0 0 3px rgba($white, 0.8);
      }

      .badge-circle {
        box-shadow: 0 0 0 2px rgba($white, 0.8);
      }
    }
  }
}

// Collapsed sidebar

// .app-sidebar-collapsed {
//   .app-sidebar {
//     &--light {
//       &:not(.app-sidebar--mini):hover {
//         .app-sidebar--userbox {
//           background: rgba($primary, 0.03);
//         }
//       }
//     }
//   }
// }
