// Core

@import "core";

// Fonts

@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,500,700&display=swap");

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.hide-apexcharts-xaxis-title {
  opacity: 0;
}

/// Other

.removeButtonPadding {
  padding: 0 !important;
}

.fixInputAdornMargin {
  margin-right: 0px !important;
}

.inputAdornColor {
  color: rgba(0, 0, 0, 0.87) !important;
}

.noMarginSwitchLabel {
  margin: 0 !important;
}
.marginSwitchLeft {
  margin-left: 10px;
}

.cardOverflowX {
  overflow-x: auto !important;
}

.dropdownUnderline {
  &:hover:before {
    border-bottom: 2px solid $primary !important;
  }
  &:after {
    border-bottom: 2px solid $primary;
  }
}

.instagramButtonColor {
  color: transparent;
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

.instagramTest {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
}

.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 50%;
  background: #fafafa;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s linear;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.22);
}

.weekDays-selector input[type="checkbox"]:checked + label {
  background: #18a9a9;
  color: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.marvel-device {
  display: inline-block;
  position: relative;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.marvel-device .screen {
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 3;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
  border-radius: 1px;
  -webkit-box-shadow: 0 0 0 3px #111;
  box-shadow: 0 0 0 3px #111;
}
.marvel-device .top-bar,
.marvel-device .bottom-bar {
  height: 3px;
  background: black;
  width: 100%;
  display: block;
}
.marvel-device .middle-bar {
  width: 3px;
  height: 4px;
  top: 0px;
  left: 90px;
  background: black;
  position: absolute;
}
.marvel-device.iphone-x {
  width: 375px;
  height: 812px;
  padding: 26px;
  background: #fdfdfd;
  -webkit-box-shadow: inset 0 0 11px 0 black;
  box-shadow: inset 0 0 11px 0 black;
  border-radius: 66px;
}
.marvel-device.iphone-x .overflow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 66px;
  overflow: hidden;
}
.marvel-device.iphone-x .shadow {
  border-radius: 100%;
  width: 90px;
  height: 90px;
  position: absolute;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 60%
  );
}
.marvel-device.iphone-x .shadow--tl {
  top: -20px;
  left: -20px;
}
.marvel-device.iphone-x .shadow--tr {
  top: -20px;
  right: -20px;
}
.marvel-device.iphone-x .shadow--bl {
  bottom: -20px;
  left: -20px;
}
.marvel-device.iphone-x .shadow--br {
  bottom: -20px;
  right: -20px;
}
.marvel-device.iphone-x:before {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute;
  top: 5px;
  content: "";
  left: 5px;
  border-radius: 61px;
  background: black;
  z-index: 1;
}
.marvel-device.iphone-x .inner-shadow {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  overflow: hidden;
  left: 10px;
  border-radius: 56px;
  -webkit-box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
  box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
  z-index: 1;
}
.marvel-device.iphone-x .inner-shadow:before {
  -webkit-box-shadow: inset 0 0 20px 0 #ffffff;
  box-shadow: inset 0 0 20px 0 #ffffff;
  width: 100%;
  height: 116%;
  position: absolute;
  top: -8%;
  content: "";
  left: 0;
  border-radius: 200px / 112px;
  z-index: 2;
}
.marvel-device.iphone-x .screen {
  border-radius: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.marvel-device.iphone-x .top-bar,
.marvel-device.iphone-x .bottom-bar {
  width: 100%;
  position: absolute;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
}
.marvel-device.iphone-x .top-bar {
  top: 80px;
}
.marvel-device.iphone-x .bottom-bar {
  bottom: 80px;
}
.marvel-device.iphone-x .volume,
.marvel-device.iphone-x .volume:before,
.marvel-device.iphone-x .volume:after,
.marvel-device.iphone-x .sleep {
  width: 3px;
  background: #b5b5b5;
  position: absolute;
}
.marvel-device.iphone-x .volume {
  left: -3px;
  top: 116px;
  height: 32px;
}
.marvel-device.iphone-x .volume:before {
  height: 62px;
  top: 62px;
  content: "";
  left: 0;
}
.marvel-device.iphone-x .volume:after {
  height: 62px;
  top: 140px;
  content: "";
  left: 0;
}
.marvel-device.iphone-x .sleep {
  height: 96px;
  top: 200px;
  right: -3px;
}
.marvel-device.iphone-x .camera {
  width: 6px;
  height: 6px;
  top: 9px;
  border-radius: 100%;
  position: absolute;
  left: 154px;
  background: #0d4d71;
}
.marvel-device.iphone-x .speaker {
  height: 6px;
  width: 60px;
  left: 50%;
  position: absolute;
  top: 9px;
  margin-left: -30px;
  background: #171818;
  border-radius: 6px;
}
.marvel-device.iphone-x .notch {
  position: absolute;
  width: 210px;
  height: 30px;
  top: 25px;
  left: 108px;
  z-index: 4;
  background: black;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
.marvel-device.iphone-x .notch:before,
.marvel-device.iphone-x .notch:after {
  content: "";
  height: 8px;
  position: absolute;
  top: 0;
  width: 8px;
}
.marvel-device.iphone-x .notch:after {
  background: radial-gradient(
    circle at bottom left,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  left: -8px;
}
.marvel-device.iphone-x .notch:before {
  background: radial-gradient(
    circle at bottom right,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  right: -8px;
}
.marvel-device.iphone-x.landscape {
  height: 375px;
  width: 812px;
}
.marvel-device.iphone-x.landscape .top-bar,
.marvel-device.iphone-x.landscape .bottom-bar {
  width: 8px;
  height: 100%;
  top: 0;
}
.marvel-device.iphone-x.landscape .top-bar {
  left: 80px;
}
.marvel-device.iphone-x.landscape .bottom-bar {
  right: 80px;
  bottom: auto;
  left: auto;
}
.marvel-device.iphone-x.landscape .volume,
.marvel-device.iphone-x.landscape .volume:before,
.marvel-device.iphone-x.landscape .volume:after,
.marvel-device.iphone-x.landscape .sleep {
  height: 3px;
}
.marvel-device.iphone-x.landscape .inner-shadow:before {
  height: 100%;
  width: 116%;
  left: -8%;
  top: 0;
  border-radius: 112px / 200px;
}
.marvel-device.iphone-x.landscape .volume {
  bottom: -3px;
  top: auto;
  left: 116px;
  width: 32px;
}
.marvel-device.iphone-x.landscape .volume:before {
  width: 62px;
  left: 62px;
  top: 0;
}
.marvel-device.iphone-x.landscape .volume:after {
  width: 62px;
  left: 140px;
  top: 0;
}
.marvel-device.iphone-x.landscape .sleep {
  width: 96px;
  left: 200px;
  top: -3px;
  right: auto;
}
.marvel-device.iphone-x.landscape .camera {
  left: 9px;
  bottom: 154px;
  top: auto;
}
.marvel-device.iphone-x.landscape .speaker {
  width: 6px;
  height: 60px;
  left: 9px;
  top: 50%;
  margin-top: -30px;
  margin-left: 0;
}
.marvel-device.iphone-x.landscape .notch {
  height: 210px;
  width: 30px;
  left: 26px;
  bottom: 108px;
  top: auto;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 0;
}
.marvel-device.iphone-x.landscape .notch:before,
.marvel-device.iphone-x.landscape .notch:after {
  left: 0;
}
.marvel-device.iphone-x.landscape .notch:after {
  background: radial-gradient(
    circle at bottom right,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  bottom: -8px;
  top: auto;
}
.marvel-device.iphone-x.landscape .notch:before {
  background: radial-gradient(
    circle at top right,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  top: -8px;
}

/* FIX CHROME AUTOFILL  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// hide text layer
.viewer-text-layer {
  display: none;
}

.inline-flex {
  display: inline-flex !important;
}

/* MENU PREVIEW */

.gradient-selector {
  background: linear-gradient(-45deg, #ee7752, #8b3dd4, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.smallerH1 {
  font-size: 2rem !important;
  font-family: Lato;
  font-weight: 400;
  line-height: 1.167 !important;
}
@media (min-width: 600px) {
  .smallerH1 {
    font-size: 2.4rem !important;
  }
}
@media (min-width: 960px) {
  .smallerH1 {
    font-size: 2.4rem !important;
  }
}
@media (min-width: 1280px) {
  .smallerH1 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 700px) {
  .previewGallery {
    width: 450px;
  }
}

.fullHeight {
  height: 100%;
}

.premiumCircle {
  background: linear-gradient(90deg, #ee7752, #8b3dd4, #23a6d5, #23d5ab);
  background-size: 1000% 1000%;
  animation: premiumGradient 10s ease infinite;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

@keyframes premiumGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.liveCircle {
  background: #029952;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.disabledCircle {
  background: #d3d3d3;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.draftCircle {
  background: linear-gradient(90deg, #ee7752, #e28801, #c70606);
  background-size: 1000% 1000%;
  animation: draftGradient 10s ease infinite;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

@keyframes draftGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Stripe */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #0066f0;
  --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --radius: 6px;
  --form-width: 343px;
}

/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #18a9a9;
  --success-color: #5fed82;
  --headline-color: var(--accent-color);
}

.AppRootCheckout {
  font-family: var(--body-font-family) !important;
  font-size: 16px !important;
  color: var(--body-color) !important;
  -webkit-font-smoothing: antialiased !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--body-color) !important;
    margin-top: 2px !important;
    margin-bottom: 4px !important;
  }
  h1 {
    font-size: 27px !important;
    color: var(--headline-color) !important;
    line-height: 30px !important;
    font-weight: 700 !important;
  }
  h4 {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: var(--gray-light) !important;
  }

  .pasha-image-stack {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
  }

  .pasha-image-stack img {
    border-radius: var(--radius);
    background-color: var(--gray-border);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.8s ease;
    opacity: 0;
  }

  .pasha-image-stack img:nth-child(1) {
    transform: translate(12px, -12px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(2) {
    transform: translate(-24px, 16px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(3) {
    transform: translate(68px, -100px);
    opacity: 1;
  }

  .sample-info {
    padding: 20px 30px;
    border: 1px solid var(--gray-border);
    border-radius: var(--radius);

    position: fixed;
    top: 10px;
    left: 50%;
    margin-left: -250px;
    max-width: 500px;
  }

  @media (max-width: 720px) {
    .sample-info {
      top: 0;
    }
  }

  .sr-combo-inputs {
    margin: 20px 0;
  }

  .sr-input {
    font-size: 16px;
  }

  .sr-card-element {
    padding-top: 12px !important;
  }

  .btn {
    font-size: 16px;
  }

  .sr-root {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 980px;
    padding: 48px;
    align-content: center;
    justify-content: center;
    height: auto;
    margin: 0 auto;
  }
  .sr-header {
    margin-bottom: 32px;
  }
  .sr-payment-summary {
    margin-bottom: 20px;
  }
  .sr-main,
  .sr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
  }
  .sr-main {
    width: var(--form-width);
  }
  .sr-content {
    padding-left: 48px;
  }
  .sr-header__logo {
    background-image: var(--logo-image);
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
  }
  .sr-legal-text {
    color: var(--gray-light);
    text-align: center;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  .sr-field-error {
    color: var(--accent-color);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }

  .sr-field-success {
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }

  /* Form */
  .sr-form-row {
    margin: 16px 0;
  }
  label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
  }

  /* Inputs */
  .sr-input,
  .sr-select,
  input[type="text"] {
    border: 1px solid var(--gray-border);
    border-radius: var(--radius);
    padding: 5px 12px;
    height: 44px;
    width: 100%;
    transition: box-shadow 0.2s ease;
    background: white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #32325d;
  }
  .sr-input:focus,
  .StripeElement--focus,
  input[type="text"]:focus,
  button:focus,
  .focused {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
    z-index: 9;
  }
  .sr-input::placeholder,
  input[type="text"]::placeholder {
    color: var(--gray-light);
  }

  /* Checkbox */
  .sr-checkbox-label {
    position: relative;
    cursor: pointer;
  }

  .sr-checkbox-label input {
    opacity: 0;
    margin-right: 6px;
  }

  .sr-checkbox-label .sr-checkbox-check {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    transition: all 0.2s ease;
  }

  .sr-checkbox-label input:focus ~ .sr-checkbox-check {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
  }

  .sr-checkbox-label input:checked ~ .sr-checkbox-check {
    background-color: var(--accent-color);
    background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-checkmark.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: -1px -1px;
  }

  /* Select */
  .sr-select {
    display: block;
    height: 44px;
    margin: 0;
    background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-chevron-down.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
  .sr-select:after {
  }
  .sr-select::-ms-expand {
    display: none;
  }
  .sr-select:hover {
    cursor: pointer;
  }
  .sr-select:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
  }
  .sr-select option {
    font-weight: 400;
  }
  .sr-select:invalid {
    color: var(--gray-light);
    background-opacity: 0.4;
  }

  /* Combo inputs */
  .sr-combo-inputs {
    display: flex;
    flex-direction: column;
  }
  .sr-combo-inputs input,
  .sr-combo-inputs .sr-select {
    border-radius: 0;
    border-bottom: 0;
  }
  .sr-combo-inputs > input:first-child,
  .sr-combo-inputs > .sr-select:first-child {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  .sr-combo-inputs > input:last-child,
  .sr-combo-inputs > .sr-select:last-child {
    border-radius: 0 0 var(--radius) var(--radius);
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:last-child input:first-child {
    border-radius: 0 0 0 var(--radius);
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:last-child input:last-child {
    border-radius: 0 0 var(--radius) 0;
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:first-child {
    border-radius: var(--radius) 0 0 0;
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:last-child {
    border-radius: 0 var(--radius) 0 0;
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:only-child {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  .sr-combo-inputs-row {
    width: 100%;
    display: flex;
  }

  .sr-combo-inputs-row > input {
    width: 100%;
    border-radius: 0;
  }

  .sr-combo-inputs-row > input:first-child:not(:only-child) {
    border-right: 0;
  }

  .sr-combo-inputs-row:not(:first-of-type) .sr-input {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  /* Buttons and links */
  button {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer !important;
    transition: all 0.2s ease;
    display: block;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }
  button:disabled {
    opacity: 0.5;
    cursor: none;
  }

  .sr-payment-form button,
  .fullwidth {
    width: 100%;
  }

  a {
    color: var(--accent-color);
    text-decoration: none;
    transition: all 0.2s ease;
  }

  a:hover {
    filter: brightness(0.8);
  }

  a:active {
    filter: brightness(0.5);
  }

  /* Code block */
  .sr-callout {
    background: var(--gray-offset);
    padding: 12px;
    border-radius: var(--radius);
    max-height: 200px;
    overflow: auto;
  }
  code,
  pre {
    font-family: "SF Mono", "IBM Plex Mono", "Menlo", monospace;
    font-size: 12px;
  }

  /* todo: spinner/processing state, errors, animations */

  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--accent-color);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: var(--accent-color);
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* Animated form */

  .sr-root {
    animation: 0.4s form-in;
    animation-fill-mode: both;
    animation-timing-function: ease;
  }

  .sr-payment-form .sr-form-row {
    animation: 0.4s field-in;
    animation-fill-mode: both;
    animation-timing-function: ease;
    transform-origin: 50% 0%;
  }

  /* need saas for loop :D  */
  .sr-payment-form .sr-form-row:nth-child(1) {
    animation-delay: 0;
  }
  .sr-payment-form .sr-form-row:nth-child(2) {
    animation-delay: 60ms;
  }
  .sr-payment-form .sr-form-row:nth-child(3) {
    animation-delay: 120ms;
  }
  .sr-payment-form .sr-form-row:nth-child(4) {
    animation-delay: 180ms;
  }
  .sr-payment-form .sr-form-row:nth-child(5) {
    animation-delay: 240ms;
  }
  .sr-payment-form .sr-form-row:nth-child(6) {
    animation-delay: 300ms;
  }

  .hidden {
    display: none;
  }

  @keyframes field-in {
    0% {
      opacity: 0;
      transform: translateY(8px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes form-in {
    0% {
      opacity: 0;
      transform: scale(0.98);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Responsiveness */
  @media (max-width: 720px) {
    .sr-root {
      flex-direction: column;
      justify-content: flex-start;
      padding: 20px 20px;
      min-width: 200px;
    }

    .sr-header__logo {
      background-position: center;
    }

    .sr-payment-summary {
      text-align: center;
    }

    .sr-content {
      display: none;
    }

    .sr-main {
      width: 100%;
    }
  }

  .pasha-image-stack {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
  }

  .pasha-image-stack img {
    border-radius: var(--radius);
    background-color: var(--gray-border);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.8s ease;
    opacity: 0;
  }

  .pasha-image-stack img:nth-child(1) {
    transform: translate(12px, -12px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(2) {
    transform: translate(-24px, 16px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(3) {
    transform: translate(68px, -100px);
    opacity: 1;
  }

  .sample-info {
    padding: 20px 30px;
    border: 1px solid var(--gray-border);
    border-radius: var(--radius);

    position: fixed;
    top: 10px;
    left: 50%;
    margin-left: -250px;
    max-width: 500px;
  }

  @media (max-width: 720px) {
    .sample-info {
      top: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  #hubspot-messages-iframe-container {
    display: none important;
    visibility: hidden;
  }
}

.fixElevationPaper {
  box-shadow: 0 0.313rem 0.225rem rgba(122, 123, 151, 0.1),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0) !important;
}

/* Review Grid CSS */
.agGridCellCenter {
  height: 100%;
  display: flex !important;
  align-items: center;
}

/* BODY */
.agGridReviewBody {
  color: #4a4a4a;
  font-size: 13px;
  line-height: 1.33333rem !important;
  font-weight: 400;
  white-space: normal;
}

/* END BODY */

.reviewGrid-reviewerName {
  font-size: 13px;
  font-weight: 300;
  color: #787878;
}

.reviewGrid-reviewerNameTag {
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: #9b9b9b;
}

.reviewGrid-noBody {
  color: #9b9b9b;
}

.reviewGrid-recommendation {
  position: relative;
  width: 80%;
  padding-left: 15px;
}

.reviewGrid-recommendation:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.reviewGrid-recommendation.is-positive:before {
  background: #547bba;
}

.reviewGrid-recommendation.is-negative:before {
  background: #ff7673;
}

.responseTextArea {
  width: 100%;
  min-height: 7.86667rem;
  resize: none;
  overflow: hidden;
  height: 116px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #9b9b9b;
  box-shadow: none;
  color: #6d6d6d;
  font-size: 0.93333rem;
  padding: 0.445rem;
  border-radius: 4px;
}

.responseTextArea:disabled {
  background-color: #e1e1e1;
}

/* Reviews List Bar */
.barListBg {
  background-color: #d6d6d6 !important;
}
.barListColor0 {
  background-color: #00e396 !important;
}
.barListColor1 {
  background-color: #feb019 !important;
}
.barListColor2 {
  background-color: #ff4560 !important;
}
.barListColor3 {
  background-color: #775dd0 !important;
}
.barListColor4 {
  background-color: #008ffb !important;
}
.barListColor5 {
  background-color: #00e396 !important;
}
.barListColor6 {
  background-color: #feb019 !important;
}
.barListColor7 {
  background-color: #ff4560 !important;
}
.barListColor8 {
  background-color: #775dd0 !important;
}
.barListColor9 {
  background-color: #008ffb !important;
}

.barListColorYellow {
  background-color: rgb(250, 203, 50) !important;
}

/* Get more reviews textbox */
.getMoreReviewsDescription {
  width: 100%;
  max-width: 400px;
  min-height: 80px;
  resize: none;
  overflow: hidden;
  height: 80px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #9b9b9b;
  box-shadow: none;
  color: #6d6d6d;
  font-size: 0.93333rem;
  padding: 0.445rem;
  border-radius: 4px;
}

.getMoreReviewsDescription::placeholder {
  color: rgba(0, 0, 0, 0.15);
}

.getMoreReviewsDescription:disabled {
  background-color: #e1e1e1;
}

.hljs {
  white-space: pre-wrap;
  font-family: "Heebo", sans-serif;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: rgba(98, 65, 133, 0.7) !important; //background
  color: white !important; //text
  border: 1px solid #84709a !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: #18a9a9 !important;
  color: white !important;
  border: 1px solid #84709a !important; //default styles include a border
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: #18a9a9 !important;
  color: white !important;
  border: 1px solid #18a9a9 !important; //default styles include a border
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #e4e7e7 !important;
  border: 1px solid #e4e7e7 !important;
  color: inherit !important;
}

.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #18a9a9 !important;
  border: 1px solid #18a9a9 !important;
  color: #fff !important;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #18a9a9 !important;
  border: 1px solid #18a9a9 !important;
  color: #fff !important;
}

/* STEPPER CHANGES */
.MuiStepIcon-text {
  fill: #fff !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #18a9a9 !important;
  font-weight: 500;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.ag-watermark {
  opacity: 0 !important;
  display: none !important;
}

.ql-editor {
  min-height: 100px;
}
